import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Helmet from 'react-helmet';
import PageContent from '../components/pageContent';

const Contact = ({ data }) => {
  const contactPage = data.contentfulPages;
  return (
    <Layout>
      <SEO title={contactPage.pageName} />
      <Helmet
        bodyAttributes={{
          class: 'dark-body',
        }}
      />
      <PageContent pageContent={contactPage.pageContent}></PageContent>
    </Layout>
  );
};

export default Contact;
export const pageQuery = graphql`
  query ContactPageQuery {
    contentfulPages(slug: { eq: "contact" }) {
      pageName
      slug
      pageContent {
        __typename
        ... on Node {
          ... on ContentfulFullWidthParagraphWithTitle {
            internal {
              type
            }
            paragraph {
              paragraph
              childMarkdownRemark {
                html
              }
            }
            title
            showParagraphTitle
          }
          ... on ContentfulParagraphWithTitleAndSideImage {
            internal {
              type
            }
            paragraph {
              paragraph
              childMarkdownRemark {
                html
              }
            }
            title
            picture {
              file {
                url
              }
              title
            }
          }
          ... on ContentfulFullWidthPicture {
            internal {
              type
            }
            picture {
              file {
                url
              }
              title
            }
          }
          ... on ContentfulFullWidthPictureWithMargins {
            internal {
              type
            }
            picture {
              file {
                url
              }
              title
            }
          }
          ... on ContentfulParagraph {
            internal {
              type
            }
            paragraph {
              paragraph
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulParagraphWithTitle {
            internal {
              type
            }
            paragraph {
              paragraph
              childMarkdownRemark {
                html
              }
            }
            title
          }
          ... on ContentfulSelectedProjects {
            internal {
              type
            }
            title
            showSeeAllProjects
            projects {
              slug
              projectCategories
              projectTitle
              projectImage {
                file {
                  url
                }
                title
              }
              shortDescription {
                shortDescription
              }
            }
          }
          ... on ContentfulAllProjects {
            internal {
              type
            }
            projects {
              slug
              projectCategories
              projectTitle
              projectImage {
                file {
                  url
                }
                title
              }
              shortDescription {
                shortDescription
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          ... on ContentfulTwoPicturesCollage {
            internal {
              type
            }
            pictureOne {
              file {
                url
              }
              title
            }
            pictureTwo {
              file {
                url
              }
              title
            }
          }
          ... on ContentfulButton {
            internal {
              type
            }
            buttonName
            buttonUrl
          }
          ... on ContentfulWhatILove {
            internal {
              type
            }
            title1
            title2
            title3
            image1 {
              file {
                url
              }
              title
            }
            image2 {
              file {
                url
              }
              title
            }
            image3 {
              file {
                url
              }
              title
            }
            description1 {
              description1
              childMarkdownRemark {
                html
              }
            }
            description2 {
              description2
              childMarkdownRemark {
                html
              }
            }
            description3 {
              description3
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`;
